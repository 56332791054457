<script setup lang="ts">

import BackToTop from './components/aaid/BackToTop.vue'
import { useRoute } from 'vue-router';
import Login from './components/Login.vue';
import { getCookie } from './shared/helpers/cookieHelpers.ts'
import Register from './components/Register.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import MeService from './shared/services/MeService';
import useUserStore from './stores/useUserStore';
import ToastMessage from './components/aaid/ToastMessage.vue';
import SideSocial from './components/aaid/SideSocial.vue';
import AppLayout from './layouts/AppLayout.vue';
import { useGtag, useState } from 'vue-gtag-next';
import VueCookieComply from '@ipaat/vue3-tailwind3-cookie-comply'
import { refreshAccessToken } from './shared/helpers/interceptors.ts';
import { generateSessionId, storeAnalytics } from './shared/helpers/AnalyticsHelper.ts';
const gtag = useGtag();
const route = useRoute()
const meService = MeService.getInstance()
const userStore = useUserStore()
const showSocial = ref(false)
const cookieInteraction = ref(false)
const reviewCookie = ref(false)
const analyticsCookie = ref(false)
const socialLogin = ref(false)
const cookieComplyTimeout: any = ref(null)
const showbackground: any = ref(false);

const preferences = [
  {
    title: 'Essential',
    description: 'These cookies are necessary for the website to function properly. They enable core functionalities such as page navigation, user authentication, and secure access. Without these cookies, certain parts of the website may not work as intended.',
    items: [
      {
        label: '',
        value: 'session',
        isRequired: true,
      },
    ],
  },
  {
    title: 'Analytics and performance',
    description: 'We use cookies to enhance your browsing experience, analyze website traffic, and understand how visitors interact with our site. This includes the use of Microsoft Clarity and Google Analytics for collecting anonymized data to measure website performance and usage patterns. These cookies help us improve our services and provide relevant content. By continuing to use our website, you consent to the use of these cookies in accordance with our Privacy Policy. https://audioaidynamics.com/privacy-policy',
    items: [
      {
        label: '',
        value: 'analytics',
        isEnabled: true,
      }
    ],
  },
  {
    "title": "Advertising Storage",
    "description": "Advertising storage cookies are used to enable and optimize advertisements displayed on our website. They help us serve ads that are more relevant to your interests, prevent showing the same ads repeatedly, and measure the effectiveness of ad campaigns. These cookies may also track your activity across websites to deliver tailored advertising.",
    "items": [
      {
        "label": "",
        "value": "ad_storage",
        "isEnabled": false
      }
    ]
  },
  {
    "title": "Personalization",
    "description": "Personalization cookies allow us to provide content and features that are more relevant to you. These cookies analyze your preferences and interactions to personalize advertisements and improve your user experience. By enabling personalization, you consent to the use of your data to tailor content and advertisements specifically to your interests.",
    "items": [
      {
        "label": "",
        "value": "ad_personalization",
        "isEnabled": false
      }
    ]
  },
  {
    "title": "User Data Tracking",
    "description": "User data tracking cookies collect information about how you use our website, such as pages visited, time spent on the site, and interactions with content. This information helps us and our partners better understand your interests and behaviors, enabling targeted advertising and improved services.",
    "items": [
      {
        "label": "",
        "value": "ad_user_data",
        "isEnabled": false
      }
    ]
  },
  {
    "title": "Analytics Storage",
    "description": "Analytics storage cookies collect anonymized data to help us analyze website performance and visitor behavior. This includes tracking metrics like page views, traffic sources, bounce rates, and user sessions. These cookies are essential for understanding how our site is used and for making data-driven improvements to enhance user experience.",
    "items": [
      {
        "label": "",
        "value": "analytics_storage",
        "isEnabled": true
      }
    ]
  },
  {
    "title": "Functionality Storage",
    "description": "Functionality storage cookies enable essential site features, such as remembering your preferences, settings, and other functionality that improves your user experience. These cookies ensure the proper functioning of the website and provide a smoother browsing experience.",
    "items": [
      {
        "label": "",
        "value": "functionality_storage",
        "isEnabled": true
      }
    ]
  },
  {
    "title": "Personalization Storage",
    "description": "Personalization storage cookies are used to store data that customizes the content and user interface for your preferences. These cookies help enhance your interaction with the website by remembering your preferences, such as language settings, region selection, and personalized content delivery.",
    "items": [
      {
        "label": "",
        "value": "personalization_storage",
        "isEnabled": false
      }
    ]
  },
  {
    "title": "Security Storage",
    "description": "Security storage cookies are essential for maintaining the security and integrity of our website. These cookies help detect and prevent fraudulent activities, safeguard your data, and ensure secure interactions with our platform.",
    "items": [
      {
        "label": "",
        "value": "security_storage",
        "isEnabled": true
      }
    ]
  },
  {
    "title": "Wait for Update",
    "description": "This setting ensures that consent preferences are updated dynamically when changes are made. By enabling this, your preferences will be synchronized with any updates or modifications made to consent settings in real time.",
    "items": [
      {
        "label": "",
        "value": "wait_for_update",
        "isEnabled": false
      }
    ]
  },
  {
    "title": "Ads Data Redaction",
    "description": "Ads data redaction ensures that sensitive or personally identifiable information (PII) is removed or redacted from advertising-related data collected through cookies. This helps maintain compliance with privacy regulations and protects your personal data.",
    "items": [
      {
        "label": "",
        "value": "ads_data_redaction",
        "isEnabled": false
      }
    ]
  },
  {
    title: 'Social login',
    description: 'These cookies facilitate user authentication through third-party platforms such as Google. They allow you to log in easily without creating a new account, ensuring a seamless and secure login experience.',
    items: [
      {
        label: '',
        value: 'social_login',
        isEnabled: true,
      }
    ],
  },
  {
    title: 'Review',
    description: 'These cookies are used to display and manage user reviews from platforms such as Sitejabber or Trustpilot. They enhance trust and transparency by showcasing genuine feedback, helping users make informed decisions.',
    items: [
      {
        label: '',
        value: 'review',
        isEnabled: true,
      }
    ],
  }
];
const checkForCookies = () => {
  let cookieComply = localStorage.getItem('cookie-comply')
  if (cookieComply) {
    cookieComply = JSON.parse(cookieComply)
    if (cookieComply && cookieComply.length) {
      reviewCookie.value = cookieComply.includes('review')
      analyticsCookie.value = cookieComply.includes('analytics')
      socialLogin.value = cookieComply.includes('social_login')
      if (reviewCookie.value) {
        addTrustPilotScript()
        addSiteJabberScript()
      }
      if (analyticsCookie.value) {
        addAnalyticsScript()
        addClarityScript()
      }
      if (socialLogin.value) {
        addSocialLoginScript()
      }
    }
  }
  if (!cookieComply || cookieComply.length === 0) {
    //@ts-ignore
    const gTag = window['gtag']
    if (gTag) {
      gTag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'ads_data_redaction': 'denied',
        'wait_for_update': 'denied',
        'security_storage': 'denied',
        'personalization_storage': 'denied',
        'functionality_storage': 'denied',
      })
    }
    cookieComplyTimeout.value = setTimeout(() => {
      cookieInteraction.value = true
    }, 3000)
  } else {
    setTimeout(() => {
      updateGoogleTags()
    }, 3000)
  }
}
const updateGoogleTags = async () => {
  let cookieComply = JSON.parse(localStorage.getItem('cookie-comply') || '[]')
  if (cookieComply && cookieComply.length > 0) {
    if (gtag) {
      let { isEnabled } = useState()
      if (isEnabled) isEnabled.value = true;
      else {
        isEnabled = ref(true)
      }
      //@ts-ignore
      const gTag = window['gtag']
      if (gTag) {
        gTag('consent', 'update', {
          'ad_storage': cookieComply.includes('ad_storage') ? 'granted' : 'denied',
          'ad_user_data': cookieComply.includes('ad_user_data') ? 'granted' : 'denied',
          'ad_personalization': cookieComply.includes('ad_personalization') ? 'granted' : 'denied',
          'analytics_storage': cookieComply.includes('analytics_storage') ? 'granted' : 'denied',
          'ads_data_redaction': cookieComply.includes('ads_data_redaction') ? 'granted' : 'denied',
          'wait_for_update': cookieComply.includes('wait_for_update') ? 'granted' : 'denied',
          'security_storage': cookieComply.includes('security_storage') ? 'granted' : 'denied',
          'personalization_storage': cookieComply.includes('personalization_storage') ? 'granted' : 'denied',
          'functionality_storage': cookieComply.includes('functionality_storage') ? 'granted' : 'denied',
        });
      }
    }
  }
}

onMounted(async () => {
  window.addEventListener('beforeunload', handleBeforeUnload);
  checkSession()
  checkForCookies()
  if (getCookie('access_token')) {
    const user = await meService.get()
    if (user && user.data) {
      userStore.setUser(user.data)
      userStore.setAuthenticated(true)
    }
  } else if (getCookie('refresh_token')) {
    await refreshAccessToken()
    const user = await meService.get()
    if (user && user.data) {
      userStore.setUser(user.data)
      userStore.setAuthenticated(true)
    }
  }
  setTimeout(() => {
    showSocial.value = true
  }, 30000)
  /* modelLoadStore.models.forEach((model: any) => {
    const worker = new FetchWorker();
    worker.postMessage({ path: model.path });

    worker.onmessage = (event: any) => {
      const { progress, data, error } = event.data;

      if (progress !== undefined) {
        modelLoadStore.setModelProgress(model.name, progress.toFixed(2));
      }

      if (data) {
        modelLoadStore.setModelLoaded(model.name, true);
        worker.terminate(); // Stop the worker when done
      }

      if (error) {
        modelLoadStore.setModelLoaded(model.name, false);
        modelLoadStore.setModelError(model.name, error);
        worker.terminate();
      }
    };
  }); */
})
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
})
const handleBeforeUnload = async () => {
  await storeAnalytics({
    exit_url: route.fullPath
  }, true)
}
const checkSession = () => {
  if (!localStorage.getItem('sessionId')) {
    localStorage.setItem('returnU', 'false')
    generateSessionId()
  } else {
    localStorage.setItem('returnU', 'true')
  }
  if (!localStorage.getItem('anonymus_ip'))
    fetch('https://ipinfo.io/json?token=e195b1c5cfe7f0')
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('anonymus_ip', data.ip)
      })
      .catch((error) => console.error('Error fetching IP info:', error));
}
const showbg = () => {
  showbackground.value = true
  setTimeout(() => {
    showbackground.value = false
  }, 100)

}
const onAccept = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
  checkForCookies()
}
const onDecline = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
  //@ts-ignore
  const gTag = window['gtag']
  if (gTag) {
    gTag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
      'ads_data_redaction': 'denied',
      'wait_for_update': 'denied',
      'security_storage': 'denied',
      'personalization_storage': 'denied',
      'functionality_storage': 'denied',
    });
    gtag.disable()
  }
}
const onSavePreferences = () => {
  clearTimeout(cookieComplyTimeout.value)
  cookieInteraction.value = false
  checkForCookies()
}
const addTrustPilotScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
  script.async = true;
  document.head.appendChild(script);
}
const addSiteJabberScript = () => {
  const jabberScript = document.createElement('script');
  jabberScript.defer = true;
  jabberScript.async = true;
  jabberScript.textContent = `
  (function(d,s,id,c){var js,rC=[],uRC=[],r=0;Array.from(document.querySelectorAll('[class*="stjr-"]')).forEach(r=>{rC=[...rC,...Array.from(r.classList).filter((cl)=>{return /^stjr-/.test(cl);})]});uRC=[...new Set(rC)];t=d.getElementsByTagName(s)[0];js=d.createElement(s);js.id=id;js.src='https://www.sitejabber.com/js/v2/66ed3fe7ea152/widgets.js'+(uRC.length ? '?widget-classes=' + uRC.join("|") : '?widget-classes=stjr-base')+'';js.onload=js.onreadystatechange=function(){if(!r&&(!this.readyState||this.readyState[0]=='c')){r=1;c();}};t.parentNode.insertBefore(js,t);}(document,'script','sj-widget',function(){}));
`;
  document.body.appendChild(jabberScript);
}
const addAnalyticsScript = () => {
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5RFDK4D7" 
          height="0" 
          width="0" 
          style="display:none;visibility:hidden"></iframe>
`;
  document.body.insertBefore(noscript, document.body.firstChild);

  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.textContent = `
  (function (w, d, s, l, i) {
      w[l] = w[l] || []; 
      w[l].push({
        'gtm.start': new Date().getTime(), 
        event: 'gtm.js'
      }); 
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), 
          dl = l != 'dataLayer' ? '&l=' + l : ''; 
      j.async = true; 
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; 
      f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5RFDK4D7');
`;
  document.head.appendChild(script);

}
const addClarityScript = () => {
  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.textContent = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "pbic3zh6hh");`;
  document.head.appendChild(script);
}
const addSocialLoginScript = () => {
  const script = document.createElement('script');
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);

}
</script>

<template>
  <div v-if="cookieInteraction"
    class="w-full fixed h-[100vh] top-0 bg-gray-700 opacity-80 z50 flex items-center justify-center">
    <span class="text-white text-lg font-bold w-[60%] max-sm:w-[320px] text-center">To continue using this website,
      please accept cookies. We use cookies to enhance your experience, analyze site usage and personalize content.
      You can accept all cookies, decline non-essential cookies or customize your preferences.</span>
  </div>
  <div class="w-full" :class="{ 'bg-blue-100': showbackground }">
    <component :is="route.meta.layout || AppLayout">
      <RouterView @on-bg-show="showbg" />
    </component>
    <BackToTop />
    <Login />
    <Register />
    <ForgotPassword />
    <ToastMessage />
    <SideSocial v-if="showSocial" />
    <VueCookieComply bannerTitle="Cookie Preferences" :preferences="preferences" @on-accept-all="onAccept"
      @on-decline-all="onDecline" @on-save-preferences="onSavePreferences">
    </VueCookieComply>
    <div v-if="reviewCookie" class="trustpilot-widget mb-5" data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66e62e2c4358e36e3659e17e"
      data-style-height="52px" data-style-width="100%">
      <a href="https://www.trustpilot.com/review/audioaidynamics.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  </div>
</template>

<style lang="scss">
.z55 {
  z-index: 9999;
  position: relative;
}

.z50,
aside {
  z-index: 999;
}

@media (max-width:480px) {
  aside>div>div.sm\:max-w-xl.mb-6.shadow-xl {
    top: 255px !important;
    position: relative;
  }
}

body>aside>div.fixed.inset-0.overflow-y-auto.px-4.py-6.sm\:px-0.z-\[1000\].flex.items-center>div.sm\:max-w-xl.mb-6.shadow-xl.transform.transition-all.sm\:w-full.sm\:mx-auto>div>main {
  max-height: 680px !important;
}
</style>
